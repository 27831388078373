import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import "./Descriptionright.css";



function DescriptionRight(blog) {
  const[blogData, setBlogData] = useState(null)
  const[keys, setKey] = useState(null)

  useEffect(()=>{
    console.log(blog,"sss2")
    const jsonObject = JSON.parse(blog.blog.content);
    console.log(jsonObject,"contenm from description right")
    console.log(jsonObject.Header)
    // console.log(jsonObject.Introduction)
    setBlogData(jsonObject)
    let temp = Object.keys(jsonObject)
    console.log(temp)
    setKey(temp)
  },[])
  return (
    <div className="rightMain">
      
      
      {blogData && keys && <div key={blogData.Header} className="headingMain" id={blogData.Header}>
          {" "}
          {/* <div className="heading">{blogData.Header}</div> */}
          {/* {blogData.map((item, index)=>{
            <p>{item}</p>
          })} */}


          {keys && keys.map((item,index)=>{
            if (item != "Header"){
              return(
                <div className="clas2" id={item}>
                  <h2>{item}</h2>
                  <p>{blogData[item]}</p>
                  </div>
              )
            }
          })}
          
          
        </div>
}
    </div>
  );
}


export default DescriptionRight;
