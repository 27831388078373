import React from "react";
import styles from "./HeroSection.module.css";
import { useAuth0 } from "@auth0/auth0-react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFlag, faUserGroup, faClipboard } from '@fortawesome/free-solid-svg-icons';

const HeroSection = () => {
  const { isAuthenticated, loginWithRedirect, loginWithPopup } = useAuth0();
  return (
    <section>
      {!isAuthenticated && (
        <div className={styles.anouncementContainer}>
          <div
            className={styles.signUpOffer}
            onClick={loginWithPopup}
            // onClick={loginWithRedirect}
          >
            Sign up offer!
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div>
          <h2 className={styles.headContain}>
            PDFing allows you to summarize and so much more{" "}
          </h2>
        </div>
        <div className={styles.heroContent}>
          {/* Items */}
          <div class="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none">
            {/* 1st item */}
            <div className={styles.customSubContainer}>
              <div className={styles.iconWrapper}>
                <div className={styles.targetIcon}></div>
              </div>
              <h4 className={styles.customHeading}>What is PDFing?</h4>
              <p className={styles.customText}>
                PDFing: (pee-dee-’eff-eeng) [new] verb – (1) Using Generative AI
                tools to review, summarize, query and streamline documents. (2)
                The act of using pdfing.ai website to enhance and accelerate
                your use of complex documents to save time and enhance knowledge
                (3) Fast, free and secure when you’re f*ing with your PDFs.
              </p>
            </div>

            {/* 2nd item */}
            <div className={styles.customSubContainer}>
              <div className={styles.iconWrapper}>
                <div className={styles.targetIcon2}></div>
              </div>
              <h4 className={styles.customHeading}>
                Why we are the best AI solution
              </h4>
              <p className={styles.customText}>
                Our platform utilizes best of breed functionality to offer a
                simple, free and powerful solution to all your GEN AI document
                needs. If you are a professional or academic who needs document
                summarization, querying, editing or review, try our site today
                and you will never need another site. We are also secure so that
                your information will never be shared or sold
              </p>
            </div>

            {/* 3rd item */}
            <div className={styles.customSubContainer}>
              <div className={styles.iconWrapper}>
                <div className={styles.targetIcon3}></div>
              </div>
              <h4 className={styles.customHeading}>How PDFing Works</h4>
              <p className={styles.customText}>
                If you are not aware how this magical site works, here is a
                little background. When you upload your document into our
                workspace, you will be able to quickly see an overview summary
                and then use our unique tools to customize your summary. You can
                also ask complex questions to your document from our AI prompt
                and get the answer you need in seconds. No more scanning and
                searching, just start a chat with your document. To access
                premium features and have unlimited usage, set up an account
                with us today.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
