import styles from "./HeadlineSection2.module.css"
import mainLogo from "../../../assets/mainLogo.svg"

function HeadlineSection2(){
    return(
        <div className={styles.sectionContainer}>
            {/* <iframe
                width="560"
                height="375"
                src="https://www.youtube.com/embed/18rARy8g4Hc?si=GxRvdhfcuRrbi1zO"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe> */}
            <img src={mainLogo} alt="Main Logo" />
        </div>
    )
}

export default HeadlineSection2