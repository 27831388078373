import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import "./Leftheading.css";
import { globalFirstKeyClick } from "../../Common/Actions";
// import AuthorImage from "../../../../assets/st.jpeg";

function LeftContent({ globalFirstKeyClick, authorImage,blog }) {
  const [activeItem, setActiveItem] = useState("");

  const headings = [
    "What is a Marketing Technology Stack?",
    "Benefits of a Marketing Technology Stack",
    "Key Components of a Marketing Technology Stack",
    "How to Implement a Marketing Technology Stack - A Step-by-Step Guide",
    "Best Practices and Tips",
    "Common Mistakes to Avoid",
    "Tools and Resources",
    "CRM Tools",
    "Marketing Automation Platforms",
  ];
  const[blogData, setBlogData] = useState(null)
  const[keys, setKey] = useState(null)

  useEffect(()=>{
    console.log(blog,"left")
    const jsonObject = JSON.parse(blog.content);
    console.log(jsonObject,"contenm from description right")
    console.log(jsonObject.Header)
    // console.log(jsonObject.Introduction)
    setBlogData(jsonObject)
    let temp = Object.keys(jsonObject)
    console.log(temp)
    setKey(temp)
  },[])
  const scrollDown = () => {
    console.log("it scrolls")
    document.getElementById("descriptionId").scrollBy({ top: 160, left: 0, behavior: 'smooth' });
    // Scrolls down by 10px
    
};

  const handleScroll = () => {
    let currentHeading = "";
    const sections = headings.map(heading => document.getElementById(heading));

    sections.forEach(section => {
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          currentHeading = section.id;
        }
      }
    });

    if (currentHeading !== activeItem) {
      setActiveItem(currentHeading);
      globalFirstKeyClick(currentHeading);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeItem, globalFirstKeyClick]);

  const handleClick = (key) => {
    setActiveItem(key);
    globalFirstKeyClick(key);

    const element = document.getElementById(key);
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="leftSideMain">
      <div className="content">CONTENTS</div>
      <div className="allLinks">
        <div className="contenthead">
          {keys && keys.map((heading) => 
            {if (heading !== "Header") return (<a
              key={heading}
              onClick={scrollDown}
              href={`#${heading}`}
              className={activeItem === heading ? "active" : ""}
            >
              {heading}
            </a>)}
          )}
        </div>
        {authorImage !== "" &&<span className="squareImage">
          <img src={authorImage} alt="Author" />
        </span>}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  globalFirstKeyClick,
};

export default LeftContent;
