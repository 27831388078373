
// function ContactPage(){
//     return(
//         <>
//         <div data-tf-live="01J77BCGR5J865HFQDQS90DZW7"></div>
//         {/* <script src="//embed.typeform.com/next/embed.js"></script> */}
        
//         </>
//     );
// }
// export default ContactPage;

import React, { useEffect } from 'react';

const ContactPage = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div data-tf-live="01J77BCGR5J865HFQDQS90DZW7"></div>
  );
};

export default ContactPage;
