
import { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import PlatformExplore from "./BlogsPart/PlatformExplore";
import RecentPosts from "./BlogsPart/RecentPosts";
import SliderPosts from "./BlogsPart/SliderPosts";
import Welcome from "./BlogsPart/Welcome";
import { useLocation, useNavigate, } from 'react-router-dom';




const BlogsPage = ({urlStats,setUrlStats,isAuthenticated,setIsAuthenticated}) => {
  const navigate = useNavigate();

  const location = useLocation();
  

  useEffect(()=>{
    console.log(location.pathname)
    setUrlStats("blogs")
  },[])

  // Access the state
  const fromPage = location.state?.from || '/';  // Fallback to '/' if state is undefined
  const message = location.state?.message || '';
  console.log("fromPage",fromPage)
  console.log("message",message)
  console.log("urlStats",urlStats)
  
    const data = "Try for free"
  return <div className="w-full flex flex-col items-center justify-center mt-20">
    <Navbar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} data={data}/>
    {/* <PlatformExplore/> */}
    <SliderPosts/>
    <RecentPosts/>
    <Welcome/>
  </div>;
};

export default BlogsPage;
