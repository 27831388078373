export const fetchData = async ()=>{
    try {
       const response = await fetch("https://pdfing.ai:7000/vlogs");
       console.log("resonse heresss",response)
       const data = await response.json();
       console.log(data)
       if(data === null){
        alert("Unable to fetch the data")
        return
      }
       console.log(data)
       return data; 
    } catch (error) {
        console.log("Error fetching data:", error);
        return [];
    }
};