import React from 'react';



function MathList(props) {
    return (
        <div>
            <ul>
                {props.Items.map((solution, index) => (
                    <li key={index}>{solution}</li>
                ))}
            </ul>
        </div>
    );
}

export default MathList;
