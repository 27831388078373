import React, { useEffect, useState } from "react";
import "./Image.css";

function Banner(blog) {
  const[blogData, setBlogData] = useState(null)

  useEffect(()=>{
    console.log(blog,"sss00000")
    const jsonObject = JSON.parse(blog.blog.content);
    console.log(jsonObject,"contenm from image right")
    console.log(jsonObject.Header)
    // console.log(jsonObject.Introduction)
    setBlogData(jsonObject)
    
  },[])
  return (
    <div className="banner">
      {blogData && (
        <div className="bannerImage">
        <span className="date">{blog.blog.date}</span>
        <div className="titleContainer">
          <h1>{blogData.Header}</h1>
          <h1>{blog.blog.type}</h1>
          {/* <h1>{blogData.Header}</h1>
          <h1>{blog.blog.type}</h1> */}
        </div>
      </div>
      )}
    </div>
  );
}

export default Banner;
