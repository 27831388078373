import { NavLink } from 'react-router-dom';
import styles from './Footer.module.css';

function Footer(){
    return(
        <div className={styles.footer}>
            <a href="/"><div className={styles.mainLogoIcon}>

            </div></a>
            <div className={styles.besideDetails}>
                {/* <div>Careers</div> */}
                <div><NavLink 
                    to="privacypolicy" 
                    style={{ color: 'white', textDecoration: 'none' }} 
                    onMouseEnter={(e) => e.target.style.textDecoration = 'none'} 
                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                    >
                    Privacy Policy
                    </NavLink>
                </div>
                <div><NavLink 
                    to="/terms-of-sevices" 
                    style={{ color: 'white', textDecoration: 'none' }} 
                    onMouseEnter={(e) => e.target.style.textDecoration = 'none'} 
                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                    >
                    Terms Of Service
                    </NavLink>
                </div>
                <div><NavLink 
                    to="contactus" 
                    style={{ color: 'white', textDecoration: 'none' }} 
                    onMouseEnter={(e) => e.target.style.textDecoration = 'none'} 
                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                    >
                    Contact Us
                    </NavLink>
                </div>
                {/* <div><NavLink 
                    to="reportbug" 
                    style={{ color: 'white', textDecoration: 'none' }} 
                    onMouseEnter={(e) => e.target.style.textDecoration = 'none'} 
                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                    >
                    Report Bug
                    </NavLink>
                </div> */}
                {/* <div>Status</div> */}
            </div>
        </div>
    )
}

export default Footer