import { Button, Card, Switch } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import "./SummaryChatbot.css";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { jsPDF } from 'jspdf';
import { AddCard, AddCardRounded, AddCircleOutline, AttachFile, BlindsClosedRounded, ClosedCaptionRounded, CloseFullscreenOutlined, CloseFullscreenRounded, CloseOutlined, CloseRounded, FileUpload, FileUploadRounded, FileUploadTwoTone, MicNone, RecordVoiceOverOutlined, UploadFileOutlined, VoiceChatOutlined, VoicemailSharp, VoiceOverOffOutlined } from '@mui/icons-material';
import AudioRecorder from "./WhisperAi.js"
import pdfToText from "react-pdftotext";


function SummaryChatbot({ isSelectedMath, setChatHistory,chatHistory, fileContent, fileName,  fileUrl, setFileUrl }) {



  const [chatMessages, setChatMessages] = useState([]);
  const [userFile, setUserFile] = useState(null);
  const [userUploadedContent, setUserUploadedContent] = useState('');
  
  const [userMessage, setUserMessage] = useState();
  const [userWebUrls, setUserWebUrls] = useState([]);
  const [websearch, setWebsearch] = useState(false);
  const [mapSearch, setMapSearch] = useState(false);
  const [showMoreFeature, setShowMoreFeature] = useState(false);


  const chatboxRef = useRef(null);
  const chatInputRef = useRef(null);
  const cardRef = useRef(null);
  
  const headerRef = useRef(null);
  const [isLoading, setIsloading] = useState(false);
  const [response, setResponse] = useState();
  const [chatInput, setChatInput] = useState('');
  const fileInputRef = useRef(null);
  


  // useEffect(() => {
  //   if (chatboxRef.current) {
  //     const { scrollHeight, clientHeight } = chatboxRef.current;
  //     const scrollPosition = scrollHeight - clientHeight - cardRef?.current?.clientHeight + 200; 
  //     chatboxRef.current.scrollTo(0, scrollPosition);
  //   }
  // }, [chatHistory]);
  useEffect(() => {
    console.log("answer3",response)
    let upscroll = 0;
    if (cardRef.current && headerRef.current){
      upscroll = cardRef.current.clientHeight + headerRef.current.clientHeight;
      console.log("Cardref:",cardRef.current.clientHeight);
    }
    if (chatboxRef.current) {
      const { scrollHeight, clientHeight } = chatboxRef.current;
      const scrollPosition = scrollHeight;
      chatboxRef.current.scrollTo({
        top: scrollPosition - upscroll,
        behavior: 'smooth',
      });
      console.log("it runs scrollPosition",scrollPosition, clientHeight)
    }
  }, [chatHistory]);

  useEffect(() => {
    console.log("theuser44",userUploadedContent)
  },[userUploadedContent])


  const handleInputChange = (event) => {
    setChatInput(event.target.value);
    setUserMessage(event.target.value);
  };
  const handleInputUrlsChange = (event) => {
    setUserWebUrls(event.target.value.split(", ").map(item => item.trim()));
    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      apicall();
    }
  };
  const handleKeyUrlDown = (event) => {
    if (event.key === 'Enter') {
      console.log(userWebUrls)
    }
  };
  const extractTextFromPDF = async (file) => {
    try {
      const text = await pdfToText(file); // file should be a valid File object (from input)
      return text;
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
    }
  };

  const handleFileChange = async(event) =>{
    console.log("file uploaded",event.target.files[0])

    const file = event.target.files[0];
    setUserFile(event.target.files[0])
    if (file && file.type === 'application/pdf') {
      const text = await extractTextFromPDF(file);
      console.log("Extracted text:", text);
      setUserUploadedContent(text);
      fileapicall(text);
    } else {
      console.error("Please upload a valid PDF file.");
    }
    if (!file) return;
  }
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleMoreFeatureChat = () =>{
    
    setShowMoreFeature(!showMoreFeature);
  }
  const handleChatDownload =() =>{
    console.log("download Hits", chatHistory);
    const doc = new jsPDF();

    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2 - 20;

    doc.setFontSize(12);
    doc.text('CHAT', margin, 10);
    let currentY = 20; // Start position for the first entry


    // Add each object to the PDF
    chatHistory.forEach((item, index) => {
      if(item.role && item.role!==""){

      // Check for page break before adding new content
      if (currentY + 20 > pageHeight) { // 20 is for the title height and spacing
        doc.addPage();
        currentY = 10; // Reset Y position for the new page
        doc.text('User Information', margin, currentY);
        currentY += 10; // Adjust after the title on the new page
      }

      // Make role bold
      doc.setFont('helvetica', 'bold');
      const roleText = `${item.role}:`;
      doc.text(roleText, margin, currentY);

      // Reset font to normal for content
      doc.setFont('helvetica', 'normal');
      const details = `${item.content}`;

      // Shift details to the right
      const detailsX = margin + 20; // Adjust this value to shift right

      // Split and get the lines for details
      const detailsLines = doc.splitTextToSize(details, maxLineWidth);

      // Add details text, adjusting Y position
      detailsLines.forEach((line, lineIndex) => {
        if (currentY + 6 + (lineIndex * 10) > pageHeight) { // Check if it fits on the current page
          doc.addPage();
          currentY = 10; // Reset Y position for the new page
          doc.text('User Information', margin, currentY);
          currentY += 10; // Adjust after the title on the new page
        }
        doc.text(line, detailsX, currentY + 6 + (lineIndex * 10)); // 10 is line height
      });

      // Update currentY to the end of the last detail line
      currentY += 6 + (detailsLines.length * 10) + 10; // Adjust for padding between entries
     
        // let text = `: `

        // doc.text(doc.splitTextToSize(text, maxLineWidth), margin, 20 + (index * 20));
      
      }
    });
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();

    // Save the generated PDF
    doc.save(`${fileName}_chatlog_${currDate}_${currTime}.pdf`);
  }


  

  const fileapicall = async (text) => {
    const API_URL = 'https://pdfing.ai:7000/makeAnswer';
    setUserMessage("");
    // setChatHistory([...chatHistory, { "role": "user", "content": chatInput }])
    console.log("FileContent:----- Calling API",fileContent)

    // if (!chatInput) {
    //   return;
    // }


    try {
      setIsloading(true);
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        

        body: JSON.stringify({
          // "query": chatInput,
          "websitelist":userWebUrls,
          "websearch": websearch ? "YES": "NO",
          "assignmenttext":text,
          "context": fileContent,
          // "history": chatHistory ? chatHistory : []

        }),
      };



      const response = await fetch(API_URL, requestOptions);
      // const data = await response.json();
      
      // console.log("data3", data.content)
      // const parsedData = JSON.parse(data.content)
      // console.log("data2",parsedData.answer);
      let data;
      if (websearch){
        
        const ans = await response.json();
        data= ans
        
    
      }else{
        data = await response.json();
      }
      // setResponse(data);
      console.log("response for questions:",data)
      setChatHistory([...chatHistory, ...data])
      setIsloading(false);

    } catch (e) {

      console.log("some error occured",e);
      setIsloading(false);

    }

  }




  const apicall = async () => {
    let API_URL=""
    if(websearchonly === true){
      API_URL='https://pdfing.ai:7000/askonlyinternet'
    }
    else{
      if(isSelectedMath){
        API_URL='https://pdfing.ai:7000/mathschat'
      }
      else{
        API_URL = 'https://pdfing.ai:7000/pdfchat';
      }
    }
    setUserMessage("");
    setChatHistory([...chatHistory, { "role": "user", "content": chatInput }])
    console.log("FileContent:-----",fileContent)

    if (!chatInput) {
      return;
    }

    let body = {
      "query": chatInput,
      "websitelist":userWebUrls,
      "websearch": websearch ? "YES": "NO",
      // "assignmenttext":userUploadedContent,
      "context": fileContent,
      "history": chatHistory ? chatHistory : []

    }
    if(websearchonly === true){
      body = {
        "query": chatInput
      }
    }
    if(isSelectedMath===true){
      body = {
        "query": chatInput,
        "context": fileContent,  
      }
    }


    try {
      setIsloading(true);
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        

        body: JSON.stringify(body),
      };



      const response = await fetch(API_URL, requestOptions);
      // const data = await response.json();
      
      // console.log("data3", data.content)
      // const parsedData = JSON.parse(data.content)
      // console.log("data2",parsedData.answer);

      let data;
      if (websearchonly === true){
        const ans = await response.json();
        const parsedResponse = JSON.parse(ans);
        console.log("response from web only:",parsedResponse)
        data= { "role": "assistant", "content": ans }
      }
      else if (websearch){
        
        const ans = await response.json();
        data= ans
        
    
      }else{
        data = await response.json();
      }
      setResponse(data);
      setChatHistory([...chatHistory, { "role": "user", "content": chatInput }, data])
      setIsloading(false);

    } catch (e) {

      console.log("some error occured",e);
      setIsloading(false);

    }

  }

  const openFileToPage = (pageNum)=>{
    const pdfPageUrl = `${fileUrl}#page=${pageNum}`;
    // Open the PDF in a new tab
    window.open(pdfPageUrl, '_blank',
      'noopener,noreferrer,width=700,height=820,scrollbars=yes,resizable=yes'
    );
  }

  const [websearchonly,setWebsearchonly] = useState(false);

  console.log("chat history", chatHistory)

  return (
    <div>

      <div className="chatbot" style={{ backgroundColor: 'white', width: "35%" }}>

        <header ref={headerRef} style={{ backgroundColor: '#0066cc' }}>
          <h2>Chat with {fileName ? fileName : "document"}</h2>
          <div style={{
                display:"flex",
                // alignContent:"center",
                alignItems:"center",
                justifyContent:"center",
                flexDirection: "column"
                
              }}>
              <div style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}>
                <div>
                  <h6>Search Type</h6>
                </div>
                <label style={{ marginLeft: "10px" }}>
                  <input
                    style={{ marginRight: "5px" , marginLeft: "5px"}}
                    type="radio"
                    name="websearch"
                    value="on"
                    checked={(websearch === false && websearchonly === false)}
                    onChange={() => {
                      setWebsearch(false);
                      setWebsearchonly(false);
                      setUserWebUrls([]);
                    }}
                  />
                  Document only
                </label>
                <label>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" , marginLeft: "5px"}}
                    name="websearch"
                    value="off"
                    checked={websearch === true}
                    onChange={() => {
                      setWebsearch(true);
                      setWebsearchonly(false);
                      setUserWebUrls([]);
                    }}
                  />
                  Web and document
                </label>
                
                <label>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" , marginLeft: "5px"}}
                    name="websearch"
                    value="off"
                    checked={websearchonly === true}
                    onChange={() => {
                      setWebsearch(false);
                      setWebsearchonly(true);
                      setUserWebUrls([]);
                    }}
                  />
                  Web only
                </label>
              </div>

                <div className="url_list">
                {(websearch || websearchonly) && <textarea
                  style={{
                    border: 'none',
                    borderBottom: '2px solid #000',
                    outline: 'none',
                    padding:0,
                    marginLeft:"2em",
                    height:'1.5em',
                    width:'20em'

                  }}
                  value={userWebUrls}
                  placeholder="Enter urls of websites"
                  spellCheck={false}
                  onChange={handleInputUrlsChange}
                  onKeyDown={handleKeyUrlDown}
                ></textarea>}
              </div>
                {/* <div>{websearch && userWebUrls}</div> */}
              </div>
              
          <span className="close-btn material-symbols-outlined" onClick={() => document.body.classList.remove('show-chatbot')}>
            close
          </span>
        </header>

        <ul className="chatbox" ref={chatboxRef}>
          {chatHistory.length === 1 && (
            <li className="chat incoming">
              <p>
                Welcome to <u style={{ color: 'red' }}>Pdfing.ai</u> You can chat with the selected pdf here.
                <br />
                <br />

                {!fileName && " Please upload a pdf to start chatting."}

              </p>

            </li>
          )}




          {chatHistory.length > 0 && chatHistory.map((message, index) => {
            let ans, answer, page, pageNo;
            console.log("Recieved message content ",message.content)

          try{
            try{ 
              ans = JSON.parse(message.content)
            }
            catch{ 
            ans = JSON.parse(message.content.slice(7, -3).trim())
          }
          answer = ans.answer
          console.log("ans:",ans)
          page = ans.page_number
          pageNo = parseInt(page.split(/[,|-]/)[0], 10);
          
          // ans = "hi"
            // ans = ans.slice(7, -3).trim();
          }catch{
            answer = message.content
          }
          return(
            
            message.content != "" &&(
              <li key={index} className={`chat ${message.role}`}>
                
              {message.role === 'user' ? (
                <div style={{
                  maxWidth: "55%",
                  padding: "10px",
                  borderRadius: "12px", backgroundColor: "#D7BDE2"
                }}>{message.content}</div>
              ) : (
                message &&
                <>
                  <span style={{
                    color: "white",
                    backgroundColor: "#2980B9",
                    padding: "5px",
                    width: "fit-content",
                    height: "auto",
                    textAlign: "center",
                  }}>PDFing</span>


                  <Card 
                    ref={cardRef}
                    sx={{
                      width: "75%", marginTop: 2, backgroundColor: "#D6EAF8",
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: 8, padding: 3
                    }}>
                    {answer}
                    <br></br>
                    {page && <strong style={{
                    color: "#0066cc",
                    cursor:"pointer"
                  }} onClick={()=>openFileToPage(pageNo)}>Page: {page}</strong>}
                  </Card>
                </>
              )}
            </li>
            )
          )})}
        </ul>


        <div className="chat-input">

          {isLoading && <div style={{ marginLeft: "30px" }} >Loading...</div>

          }


          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginLeft: "10px" }}>

            <div style={{
              position:"absolute",
              right:"10px",
              bottom:"90px",
              backgroundColor:"#257cda",
              display:"flex",
           
              alignItems:"center",
              justifyContent:"center",
              color:"#fff",
              height:"40px",
              width:"40px",
              borderRadius:"60%",
              cursor:"pointer"
            }} onClick={handleChatDownload}>
              <DownloadOutlinedIcon />
            </div>




          </div>

          <div style={{ display: "flex", borderTop: "1px solid #ddd" }}>
            {/* <div style={{
              display:"flex",
           
              alignItems:"center",
              justifyContent:"center",
              color:"#000",
              marginRight:"1em",
              cursor:"pointer"
            }} onClick={handleMoreFeatureChat}>
              { showMoreFeature ? <CloseOutlined/> : <AddCircleOutline/>}
            </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FFF",
                cursor: "pointer",
                backgroundColor: "#257CDA",
                // height: "4rem",
                width: "4rem",
                borderRadius: "50%"
              }}
              onClick={() => fileInputRef.current && fileInputRef.current.click()} // Trigger the file input click
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept={mapSearch ? ".pdf,.jpg" : ".pdf"}
                style={{ display: "none"}}
              />
              <AttachFile />
            </div>


            <textarea
              value={userMessage}
              placeholder="Enter a message..."
              spellCheck={false}
              ref={chatInputRef}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            ></textarea>

<div style={{
              position:"relative",
              left:"-22px",
              bottom:"-1px",
              // border:"1px solid #000",
              display:"flex",
              flexDirection:"column",
              alignItems:"start",
              justifyContent:"center",
              
            }}>
              {/* <div style={{
                display:"flex",
                // alignContent:"center",
                alignItems:"center",
                justifyContent:"center",
                
              }}>
                <div>Map</div>
                <div style={{
                
                cursor:"pointer"
              }}>
                  <Switch 
                    checked={mapSearch} 
                    onChange={() => {
                      setMapSearch(!mapSearch);
                      // setUserWebUrls([]);
                    }} 
                  />
                </div>
              </div> */}
              
              <div style={{
                display:"flex",
                // alignContent:"center",
                alignItems:"center",
                justifyContent:"center",
                gap:"12px",
                cursor:"pointer",
                // width: "4rem"
              }}>
                <div><AudioRecorder setChatInput={setChatInput} setUserMessage = {setUserMessage}/></div>
              </div>
            </div> 

            <span id="send-btn" className="material-symbols-rounded" style = {{display: "flex", alignItems: "center"}} onClick={apicall}>
              <i style={{ fontSize: "25px" }} class="ri-arrow-right-circle-line"></i>
            </span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SummaryChatbot
