import React from 'react';
import styles from './PopModal.module.css';

const PopModal = ({ onClose }) => {
  return (
    <div className={styles.containerPopup}>
      <div className="popup-container">
        <h2>We value your privacy</h2>
        <p>We use cookies to enhance your browsing experience, improve our content delivery, and analyze our traffic. We do not use cookies for advertising or marketing purposes. By using this website, you consent to our use of cookies. You can learn more about how we collect and use information by reviewing our privacy policy..</p>
        <button className={styles.acceptBtn} onClick={onClose}>Got it!</button>
      </div>
    </div>
  );
};

export default PopModal;