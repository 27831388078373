import React, { useEffect } from 'react';

const ReportBug = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs only once on mount

  return (
    <div data-tf-live="01J6Q3KYHAAV1CQC2QSVM0CMR7"></div>
  );
};

export default ReportBug;
