import TermsOfService from "./TermsOfService.js"; 
import Content from "./Content.js";
import Navbar from "../Navbar/Navbar.jsx";
import { useEffect } from "react";


function PrivacyPolicyPage({isAuthenticated,setIsAuthenticated}){
    const data = "Try for free"
    const heading = "Privacy Policy"
    const isPolicy = true

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    
    return(
        <div className="grid grid-cols-1 gap-0 bg-white mt-12">
            <Navbar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} data={data}/>
            <TermsOfService heading={heading}/>
            <Content isPolicy={isPolicy}/>
        
        </div>
    );
}
export default PrivacyPolicyPage;