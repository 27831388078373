import React from "react";
import Navbar from "../Navbar/Navbar"
import styles from "./PageNotFound.module.css"

function PageNotFound(){
    return(
        <div>
            <Navbar data={"Try for Free"}></Navbar>
            <div className={styles.Main}>
                <div className={styles.Text}>
            Page Not Found
                </div>
            </div>
        </div>
    )
}
export default PageNotFound;