import React, { useEffect, useState } from "react";
import styles from "./FeatureSection.module.css";
import { Link } from "react-router-dom";
import blogs from "../../../dataJson/blogsData.json";
import { fetchData } from "../../Blogs/utils/api";
import { NavLink, useNavigate } from "react-router-dom";



function FeatureSection() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const getPosts = async () => {
      const data = await fetchData();
      setPosts(data.slice(0, 3));
    };
    getPosts();
  }, []);

  async function handlePostClick(postId) {
    console.log("its Cliked 3", postId)
    try {
      navigate(`/blog/${postId}`)

    } catch (error) {
      console.log("Error fetching data:", error);
    }

  }

  return (
    <section>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionPadding}>
          <h3 className={styles.titleBlogs}>Blogs</h3>
        </div>
        <div className={styles.blogSection}>
          {posts.map((blog, index) => {
            console.log(blog.image_url.substring(2, blog.image_url.length - 2))
            return (

              <div onClick={() => handlePostClick(blog.id)} key={index} className={styles.contentBlog}>
                <div
                  className={styles.contentBlogImg}
                  style={{
                    width: "100%",
                    height: "17em",
                    background: `
              linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)),
              url(${blog.image_url.substring(2, blog.image_url.length - 2)}) no-repeat center center`,
                    backgroundSize: "cover",
                  }}
                >
                  <h3 style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    paddingRight: "1rem"
                  }}>{blog.heading}</h3>
                </div>
                <div className={styles.contentBlogText}>
                  <div className={styles.headTextBlog}>{blog.heading}</div>
                  <div className={styles.paraTextBlog}>{blog.preview}</div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.moreButtonContainer}>
          <NavLink
            to="/blogs"
            className={styles.moreButton}
          // onMouseEnter={(e) => (e.target.style.textDecoration = "none")}
          // onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            More Blogs
          </NavLink>
          {/* <div className={styles.moreButton}>
            More Blogs</div> */}
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
