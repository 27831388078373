import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Button, IconButton, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';



const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ShareableLink = styled.a`
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const CopyButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;


const customStyles = {
  content: {
    width: '500px',
    height: '220px',
    margin: 'auto',

  },
};

AWS.config.update({
  accessKeyId: 'AKIAZIEPTS2346N7HDXU',
  secretAccessKey: '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',
  region: 'eu-north-1',
});

//const S3_BUCKET = 'parvbucket12';
const S3_BUCKET = 'pdfing.ai';



export const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: 'eu-north-1',
});


function ShareLinkComponent({ response }, { fileName }) {


  const [shareableLink, setShareableLink] = useState('');
  const [modalOpen, setModalOpen] = useState(false)


  const generateTimestamp = () => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${month}-${day}_${hours}-${minutes}-${seconds}`;
  };



  const wrapHtmlContent = (htmlContent) => {
    return `
          <!DOCTYPE html>
          <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
          <head><meta charset='utf-8'><title>Document</title></head><body>${htmlContent}</body></html>
        `;
  };

  // const shortenUrl = async (longUrl) => {
  //   try {
  //     console.log("in shortner:", longUrl);
  //     const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  //     const apiUrl = `https://ulvis.net/api.php?url=${encodeURIComponent(longUrl)}`;
  //     const response = await fetch(proxyUrl + apiUrl);
  //     const shortUrl = await response.text(); // Get the short URL as a plain text response
  //     console.log("shortended: ",shortUrl)
  //     return shortUrl;
  //   } catch (error) {
  //     console.error('Error shortening URL:', error);
  //     return null;
  //   }
  // };

  const handleDownloadDOC = async (response, filename) => {


    const wrappedHtml = wrapHtmlContent(response);
    const blob = new Blob(['\ufeff', wrappedHtml], {
      type: 'application/msword',
    });




    try {
      const s3Url = await uploadToS3(blob, `${generateTimestamp()}.doc`);
      // setShareableLink(s3Url); 
      // Shorten the S3 URL
      // const shortUrl = await shortenUrl(s3Url);

      // if (shortUrl) {
      //   setShareableLink(shortUrl); // Save the shortened URL in the shareable link state
      // } else {
      setShareableLink(s3Url); // Fallback to original URL if shortening fails
      // }
      setModalOpen(true)
    } catch (error) {
      console.error('Error uploading file to S3:', error);
    }
  };


  const uploadToS3 = async (file, filename) => {

    console.log("file name-----", filename)
    if (!file) return;



    const params = {
      Bucket: S3_BUCKET,
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: 'application/msword',
    };


    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  };



  return (
    <div>
      <Tooltip title="Share">
        <IconButton onClick={() => handleDownloadDOC(response, generateTimestamp())} ><ShareIcon /></IconButton>
      </Tooltip>
      {shareableLink && (
        <Modal style={customStyles} isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
          <ModalWrapper>
            <h2 style={{ marginBottom: "10px" }} >Shareable Link:</h2>
            <ShareableLink href={shareableLink} target="_blank" rel="noopener noreferrer">
              {shareableLink}
            </ShareableLink>
            <CopyButton onClick={() => navigator.clipboard.writeText(shareableLink)}>
              Copy Link
            </CopyButton>
          </ModalWrapper>
        </Modal>
      )}
    </div>
  )
}

export default ShareLinkComponent
