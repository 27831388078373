import { useAuth0 } from "@auth0/auth0-react";
import styles from "./HeadlineSection1.module.css"

function HeadlineSection1(){
  const { isAuthenticated, loginWithRedirect, loginWithPopup } = useAuth0();

    return(
        <div className={styles.sectionContainer}>
            <div className={styles.subHeading}>
                <h2>Make our PDF summarizer work for you</h2>
                <div className={styles.tryButton} onClick={()=>{
                    // !isAuthenticated && loginWithRedirect()
                    !isAuthenticated && loginWithPopup()
                }}><a href="/try" >See it in Action</a></div>
                {/* <h2 className={styles.secondHead}>width of the container</h2> */}
            </div> 
            <div>
                {/* <p className={styles.subPara}>ake up equal space across the full width of the container, with each section expanding to fill half of the available space. flex value if you want to change the space allocation between the two sections.entity management operations</p> */}
            </div>
        </div>
    )
}

export default HeadlineSection1